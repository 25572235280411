import { DateRange } from 'react-date-range'
import isBefore from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'
import { MouseEventHandler, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import format from 'date-fns/format'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { FormattedMessage, useIntl } from 'react-intl'

export default function DateRangeSelect() {
  const [, setSearchParams] = useSearchParams()
  const intl = useIntl()
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ])

  const handleLoadDays: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()

    if (!Array.isArray(dateRange) || !dateRange.length) {
      return
    }

    const start = dateRange[0].startDate
    const end = dateRange[0].endDate

    if (start === null || end === null) {
      return
    }

    if (!isValid(start)) {
      alert(intl.formatMessage({ id: 'plan-dates.alert.from-date.invalid' }))
      return
    }

    if (!isValid(end)) {
      alert(intl.formatMessage({ id: 'plan-dates.alert.to-date.invalid' }))
      return
    }

    if (isBefore(end, start)) {
      alert(intl.formatMessage({ id: 'plan-dates.alert.to-date.before-from-date' }))
      return
    }

    setSearchParams({
      from: format(start, 'yyyy-MM-dd'),
      to: format(end, 'yyyy-MM-dd')
    })
  }

  return (
    <div className="uk-inline">
      <button className="uk-button uk-button-default" type="button">
        <FormattedMessage id="plan-dates.link" />
      </button>
      <div className="uk-card uk-card-body uk-card-default uk-width-large" uk-drop="pos: bottom-right; offset: 0; delay-hide: 0">
        <div uk-grid="true">
          <DateRange
            editableDateInputs={false}
            onChange={(item) => setDateRange([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
          />
          <button className="uk-button uk-margin-left uk-button-primary" onClick={handleLoadDays}>
            <FormattedMessage id="plan-dates.load-days.link" />
          </button>
        </div>
      </div>
    </div>
  )
}
