import { CognitoIdentityProviderClient, InitiateAuthCommand, AuthFlowType } from '@aws-sdk/client-cognito-identity-provider'
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ActionStatus } from '../types/actions'

export interface AuthState {
  signInStatus: ActionStatus
  hydrateSignInStatus: ActionStatus
  signOutStatus: ActionStatus
  error: string | null | undefined
  userId: string | null
  idToken: string | null | undefined
  idTokenExpires: number | null
  emailAddress: string | null
  isUserConfirmed: boolean | null
  codeDeliveryMedium: string | null
  codeDeliveryDestination: string | null
}

export interface SignInRequest {
  email: string
  password: string
}

const initialState: AuthState = {
  signInStatus: 'idle',
  hydrateSignInStatus: 'idle',
  signOutStatus: 'idle',
  error: null,
  userId: null,
  idToken: null,
  idTokenExpires: null,
  emailAddress: null,
  isUserConfirmed: null,
  codeDeliveryMedium: null,
  codeDeliveryDestination: null
}

/**
 * Actions.
 */
const cognitoClient = new CognitoIdentityProviderClient({ region: 'ap-southeast-2' })
const COGNITO_CLIENT_ID = '77ki2erchn2bb1gt8ld4ginoud'

export const signIn = createAsyncThunk('auth/signIn', async (request: SignInRequest) => {
  const response = await cognitoClient.send(
    new InitiateAuthCommand({
      ClientId: COGNITO_CLIENT_ID,
      AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
      AuthParameters: { USERNAME: request.email, PASSWORD: request.password }
    })
  )

  if (response.AuthenticationResult?.IdToken && response.AuthenticationResult?.ExpiresIn) {
    const expiryTtlMs = response.AuthenticationResult.ExpiresIn * 1000
    const tokens = {
      id: response.AuthenticationResult.IdToken,
      expires: Date.now() + expiryTtlMs
    }

    localStorage.setItem('id_token', tokens.id)
    localStorage.setItem('id_token_expiry', `${tokens.expires}`)

    return tokens
  }

  throw new Error('No authentication result returned from Cognito!')
})

export const hydrateSignIn = createAsyncThunk('auth/hydrateSignIn', async () => {
  const idToken = localStorage.getItem('id_token')
  const idTokenExpires = localStorage.getItem('id_token_expiry')

  return {
    id: idToken,
    expires: idTokenExpires ? parseInt(idTokenExpires) : null
  }
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
  localStorage.removeItem('id_token')
  localStorage.removeItem('id_token_expiry')
  window.location.reload()
})

/**
 * Reducers.
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(signIn.pending, (state, action) => {
        state.signInStatus = 'loading'
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.signInStatus = 'succeeded'
        state.idToken = action.payload.id
        state.idTokenExpires = action.payload.expires
      })
      .addCase(signIn.rejected, (state, action) => {
        state.signInStatus = 'failed'
        state.error = action.error.message
      })
      .addCase(hydrateSignIn.pending, (state, action) => {
        state.hydrateSignInStatus = 'loading'
      })
      .addCase(hydrateSignIn.fulfilled, (state, action) => {
        state.hydrateSignInStatus = 'succeeded'
        state.idToken = action.payload.id
        state.idTokenExpires = action.payload.expires
      })
      .addCase(hydrateSignIn.rejected, (state, action) => {
        state.hydrateSignInStatus = 'failed'
        state.error = action.error.message
      })
      .addCase(signOut.pending, (state, action) => {
        state.signOutStatus = 'loading'
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.idToken = null
        state.idTokenExpires = null
      })
      .addCase(signOut.rejected, (state, action) => {
        state.signInStatus = 'failed'
        state.error = action.error.message
      })
  }
})

/**
 * Selectors.
 */
const selectSelf = (state: RootState) => state.auth

export const selectSignInStatus = createSelector(selectSelf, (state) => state.signInStatus)
export const selectHydrateSignInStatus = createSelector(selectSelf, (state) => state.hydrateSignInStatus)
export const selectSignOutStatus = createSelector(selectSelf, (state) => state.signOutStatus)
export const selectEmailAddress = createSelector(selectSelf, (state) => state.emailAddress)
export const selectIdToken = createSelector(selectSelf, (state) => state.idToken)
export const selectIdTokenExpires = createSelector(selectSelf, (state) => state.idTokenExpires)

export default authSlice.reducer
