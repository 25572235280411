/* eslint-disable import/no-anonymous-default-export */
export default {
  'logo.label': 'F800 Meal Planner',
  'menu.edit-plan': 'Edit Plan',
  'menu.view-plan': 'View Plan',
  'menu.shopping-list': 'Shopping List',
  'menu.sign-out.link': 'Sign Out',
  'sign-in.title': 'Sign in to your account',
  'sign-in.sub-title': "Or, if you don't have an account",
  'sign-in.sub-title.sign-up.link': 'sign up',
  'sign-in.form.email.label': 'Email address',
  'sign-in.form.password.label': 'Password',
  'sign-in.form.forgot.link': 'Forgot your password?',
  'sign-in.form.submit.link': 'Sign in',
  'sign-in.sms-challenge.title': 'Enter your SMS code',
  'sign-in.sms-challenge.form.sms-code.label': 'SMS code',
  'sign-in.sms-challenge.form.submit.link': 'Sign in',
  'plan-dates.none-selected.label': 'Select dates from the above input fields',
  'plan-dates.link': 'Plan Dates',
  'plan-dates.load-days.link': 'Load days',
  'plan-dates.alert.from-date.invalid': 'From date is invalid!',
  'plan-dates.alert.to-date.invalid': 'To date is invalid!',
  'plan-dates.alert.to-date.before-from-date': 'To date must be after from date!',
  'calendar.day.total-calories': 'Calories: {totalCalories} ({remainingOverLabel}: {remainingOver})',
  'recipe-select.placeholder': 'Select a recipe...',
  'recipe-select.filter.placeholder': 'Filter recipes...',
  'view-plan.day.meal.book.name': 'Book: {bookName}',
  'view-plan.day.meal.book.page': 'Page: {page}',
  'view-plan.day.meal.breakfast': 'Breakfast',
  'view-plan.day.meal.lunch': 'Lunch',
  'view-plan.day.meal.dinner': 'Dinner',
  'view-plan.day.meal.treat': 'Treat',
  'quantity.unit.UNIT': '{quantity}',
  'quantity.unit.MILLILITER': '{quantity}ml',
  'quantity.unit.GRAM': '{quantity}g',
  'quantity.unit.TEASPOON': '{quantity} tsp',
  'quantity.unit.TABLESPOON': '{quantity} tbsp',
  'quantity.unit.SMALL_HANDFULL': '{quantity, plural, one {# (small handfull)} other {# (small handfulls)}}',
  'quantity.unit.LARGE_HANDFULL': '{quantity, plural, one {# (large handfull)} other {# (large handfulls)}}',
  'quantity.unit.PINCH': '{quantity} (pinch)',
  'quantity.unit.SHEET': '{quantity, plural, one {# sheet} other {# sheets}}',
  'quantity.unit.SERVE': '{quantity, plural, one {# serve} other {# serves}}',
  'department.name.FROZEN': 'Frozen',
  'department.name.PANTRY': 'Pantry',
  'department.name.FRIDGE_AND_DELI': 'Fridge and Deli',
  'department.name.FRUIT_AND_VEG': 'Fruit and Veg',
  'department.name.MEAT_AND_POULTRY': 'Meat and Poultry',
  'department.name.FISH_AND_SEAFOOD': 'Fish and Seafood',
}
