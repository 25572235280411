import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AuthenticatedView from '../AuthenticatedView'
import SignIn from '../SignIn'
import { Path } from '../../types/routes'
import { IntlProvider } from 'react-intl'
import messages from '../../translations/en'
import Calendar from '../Calendar'
import ViewPlan from '../ViewPlan'
import ShoppingList from '../ShoppingList'

const App = () => {
  return (
    <IntlProvider messages={messages} locale={navigator.language} defaultLocale="en">
      <BrowserRouter>
        <Routes>
          <Route path={Path.SignIn} element={<SignIn />} />
          <Route
            path="/view-plan"
            element={
              <AuthenticatedView>
                <ViewPlan />
              </AuthenticatedView>
            }
          />
          <Route
            path="/shopping-list"
            element={
              <AuthenticatedView>
                <ShoppingList />
              </AuthenticatedView>
            }
          />
          <Route
            path="*"
            element={
              <AuthenticatedView>
                <Calendar />
              </AuthenticatedView>
            }
          />
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  )
}

export default App
