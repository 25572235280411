import { gql, useQuery } from '@apollo/client'
import isSameDay from 'date-fns/isSameDay'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import parseISO from 'date-fns/parseISO'
import { useEffect, useState } from 'react'
import ActivityIndicator from '../ActivityIndicator'
import { Book, BookConnection, WeekDay, WeekDayConnection } from '../../types/schema'
import Day from './Day'
import { useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

const GET_WEEK_DAYS = gql`
  query {
    listWeekDays {
      items {
        date
        breakfastRecipe {
          id
          name
          calories
        }
        lunchRecipe {
          id
          name
          calories
        }
        dinnerRecipe {
          id
          name
          calories
        }
        treatRecipe {
          id
          name
          calories
        }
      }
    }
  }
`
const GET_BOOK_RECIPES = gql`
  query {
    listBooks {
      items {
        id
        name
        image
        recipes {
          items {
            id
            name
            page
            calories
          }
        }
      }
    }
  }
`

export default function Calendar() {
  const [searchParams] = useSearchParams()
  const [dates, setDates] = useState<Date[]>([])
  const {
    loading: isLoadingWeekDays,
    error: weekDaysError,
    data: plannedDays
  } = useQuery<{ listWeekDays: WeekDayConnection }>(GET_WEEK_DAYS)
  const {
    loading: isLoadingBookRecipes,
    error: bookRecipesError,
    data: bookRecipes
  } = useQuery<{ listBooks: BookConnection }>(GET_BOOK_RECIPES)

  useEffect(() => {
    if (searchParams.get('from') !== null && searchParams.get('to') !== null) {
      const start = parseISO(searchParams.get('from') as string)
      const end = parseISO(searchParams.get('to') as string)

      setDates(eachDayOfInterval({ start, end }))
    }
  }, [searchParams])

  if (isLoadingWeekDays || isLoadingBookRecipes) {
    return <ActivityIndicator fullscreen />
  }

  const error = weekDaysError || bookRecipesError

  if (error) {
    console.error(error)
    return <div>{JSON.stringify(error)}</div>
  }

  const books = bookRecipes?.listBooks.items

  if (!books) {
    return <p>ERROR: Failed to load recipe books!</p>
  }

  const findPlannedDay = (date: Date): WeekDay | undefined => {
    const plannedDay = plannedDays?.listWeekDays.items?.find((pd) => {
      if (!pd?.date) {
        return false
      }

      return isSameDay(date, parseISO(pd.date))
    })

    if (!plannedDay) {
      return
    }

    return plannedDay
  }

  return (
    <div>
      <div className="uk-padding">
        {!dates.length ? (
          <div className="uk-container uk-text-center">
            <div className="uk-alert uk-alert-primary">
              <FormattedMessage id="plan-dates.none-selected.label" />
            </div>
          </div>
        ) : (
          <>
            <div className="uk-grid uk-grid-small uk-child-width-1-6">
              {dates.map((d) => (
                <div key={`${d.toISOString()}-day`} className="uk-margin-bottom">
                  <Day date={d} weekDay={findPlannedDay(d)} books={books as Book[]} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
