import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  hydrateSignIn,
  selectHydrateSignInStatus,
  selectIdToken,
  selectIdTokenExpires,
  selectSignInStatus,
  signIn,
  signOut
} from '../slices/auth'
import { AppDispatch } from '../store'

export interface AuthContext {
  isLoading: boolean
  idToken: string | null | undefined
  isSignedIn: boolean | null
  signIn: (email: string, password: string) => Promise<void>
  hydrateSignIn: () => Promise<void>
  signOut: () => Promise<void>
}

export default function useAuth(): AuthContext {
  const [isLoading, setIsLoading] = React.useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const idToken = useSelector(selectIdToken)
  const idTokenExpires = useSelector(selectIdTokenExpires)
  const isIdTokenExpired = !idTokenExpires || Date.now() > idTokenExpires
  const signInStatus = useSelector(selectSignInStatus)
  const hydrateSignInStatus = useSelector(selectHydrateSignInStatus)
  const isHydratingSignIn = hydrateSignInStatus === 'loading'

  return {
    isLoading: isLoading || isHydratingSignIn,
    idToken: isLoading || isHydratingSignIn ? null : idToken,
    isSignedIn: isLoading || isHydratingSignIn ? null : !!idToken && !isIdTokenExpired,
    signIn: async (email: string, password: string) => {
      setIsLoading(true)

      await dispatch(signIn({ email, password }))

      setIsLoading(false)
    },
    hydrateSignIn: async () => {
      if (signInStatus === 'loading') {
        return
      }

      setIsLoading(true)

      await dispatch(hydrateSignIn())

      setIsLoading(false)
    },
    signOut: async () => {
      setIsLoading(true)

      await dispatch(signOut())

      setIsLoading(false)
    }
  }
}
