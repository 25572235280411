/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEventHandler, MouseEventHandler, useRef, useState } from 'react'
import { Book, Recipe } from '../../types/schema'
import UIkit from 'uikit'
import { FormattedMessage, useIntl } from 'react-intl'

export type ChangeHandler = (recipe: Recipe | undefined) => void

export interface RecipeSelectProps {
  books: Book[]
  defaultValue?: Recipe
  onChange?: ChangeHandler
}

const renderBookRecipes = (book: Book, selectedRecipe: Recipe | undefined, filterText: string, onChange: ChangeHandler) => {
  const makeClickHandler: (recipe: Recipe) => MouseEventHandler<HTMLAnchorElement> = (recipe: Recipe) => (event) => {
    event.preventDefault()

    if (onChange) {
      onChange(recipe)
    }
  }

  const handleNonSeletion: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()

    onChange(undefined)
  }

  return (
    <ul className="uk-list uk-list-small uk-list-divider uk-text-small">
      {book.recipes.items
        ?.filter((r) => {
          if (!filterText.length) {
            return true
          }

          return r?.name.toLowerCase().includes(filterText.toLowerCase())
        })
        .map((r) => (
          <li key={`${r?.id}-recipe`}>
            <a href="#" className="uk-link-reset" onClick={r ? makeClickHandler(r) : undefined}>
              <div className="uk-grid-collapse" uk-grid="true">
                <div className="uk-width-1-1">
                  <div className="uk-grid-small" uk-grid="true">
                    <div className="uk-width-expand">{r?.name}</div>
                    {r?.id === selectedRecipe?.id && (
                      <div className="uk-width-1-6">
                        <button className="uk-button uk-button-text uk-text-meta" onClick={handleNonSeletion}>
                          x
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="uk-width-1-2 uk-text-meta uk-text-light">Page: {r?.page}</div>
                <div className="uk-width-1-2 uk-text-meta uk-text-light">Cal: {r?.calories}</div>
              </div>
            </a>
          </li>
        ))}
    </ul>
  )
}

export default function RecipeSelect(props: RecipeSelectProps) {
  const dropRef = useRef(null)
  const intl = useIntl()
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | undefined>(props.defaultValue)
  const [filterText, setFilterText] = useState('')

  const handleChange = (recipe: Recipe | undefined) => {
    setSelectedRecipe(recipe)

    if (dropRef && dropRef.current) {
      UIkit.drop(dropRef.current).hide(false)
    }

    if (props.onChange) {
      props.onChange(recipe)
    }
  }

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault()

    setFilterText(event.currentTarget.value)
  }

  return (
    <div className="uk-inline uk-width-1-1 recipe-select">
      <button className="uk-button uk-button-default uk-button-small uk-text-capitalize uk-text-left uk-width-1-1" type="button">
        <div className="uk-grid">
          <div className="uk-text-truncate uk-width-expand">
            {selectedRecipe ? selectedRecipe.name : <FormattedMessage id="recipe-select.placeholder" />}
          </div>
          <div className="uk-width-1-5">&#x25BC;</div>
        </div>
      </button>
      <div ref={dropRef} className="uk-card uk-card-body uk-card-default uk-card-small uk-width-1-1" uk-drop="mode: click; offset: 0">
        <div className="uk-grid uk-grid-small uk-margin-bottom" uk-grid="true">
          <div className="uk-width-expand">
            <input
              type="text"
              className="uk-input uk-form-small uk-width-1-1"
              placeholder={intl.formatMessage({ id: 'recipe-select.filter.placeholder' })}
              value={filterText}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="uk-height-medium uk-overflow-auto">
          <ul className="uk-list">
            {props.books
              .filter((book) => book.recipes.items?.length)
              .map((book) => (
                <li key={`${book.id}-book-li`}>
                  {book.image ? (
                    <div className="uk-grid-small" uk-grid="true">
                      <div className="uk-width-1-3">
                        <img src={book.image} alt={book.name} />
                      </div>
                      <div className="uk-width-2-3">
                        <span className="uk-text-middle uk-text-bold">{book.name}</span>
                      </div>
                    </div>
                  ) : (
                    book.name
                  )}
                  <ul className="uk-list">
                    <li key={`${book.id}-book`}>{renderBookRecipes(book, selectedRecipe, filterText, handleChange)}</li>
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
