import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { Path } from '../../types/routes'
import ActivityIndicator from '../ActivityIndicator'
import Container from '../Container'
import useAuth from '../../hooks/auth'

export default function AuthenticatedView({ children }: { children: React.ReactElement }) {
  const { isLoading, hydrateSignIn, isSignedIn, idToken } = useAuth()

  useEffect(() => {
    hydrateSignIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return <ActivityIndicator fullscreen />
  }

  if (isSignedIn) {
    const apolloClient = new ApolloClient({
      uri: 'https://fx6jrlgyzvef3kxlgtp4lwuka4.appsync-api.ap-southeast-2.amazonaws.com/graphql',
      cache: new InMemoryCache(),
      headers: {
        Authorization: idToken!
      }
    })

    return (
      <ApolloProvider client={apolloClient}>
        <Container>{children}</Container>
      </ApolloProvider>
    )
  }

  return <Navigate to={Path.SignIn} />
}
