import * as React from 'react'
import useAuth from '../../hooks/auth'
import DateRangeSelect from '../DateRangeSelect'
import { Link, useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

export interface ContainerProps {
  children: React.ReactElement
}

export default function Container({ children }: ContainerProps) {
  const { signOut } = useAuth()
  const [searchParams] = useSearchParams()

  const handleSignOut: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault()
    await signOut()
  }

  return (
    <div className="flex h-screen">
      <nav className="uk-navbar-container uk-padding-small uk-margin">
        <div uk-navbar="true">
          <div className="uk-navbar-left">
            <div className="uk-logo">
              <FormattedMessage id="logo.label" />
            </div>
            <Link to={`/?${searchParams}`}>
              <FormattedMessage id="menu.edit-plan" />
            </Link>
            <Link to={`/view-plan?${searchParams}`}>
              <FormattedMessage id="menu.view-plan" />
            </Link>
            <Link to={`/shopping-list?${searchParams}`}>
              <FormattedMessage id="menu.shopping-list" />
            </Link>
          </div>
          <div className="uk-navbar-right">
            <DateRangeSelect />
            <button className="uk-button uk-button-secondary" onClick={handleSignOut}>
              <FormattedMessage id="menu.sign-out.link" />
            </button>
          </div>
        </div>
      </nav>
      <div className="container bg-gray-100 p-10">{children}</div>
    </div>
  )
}
