import { gql, useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Maybe, Recipe, WeekDayConnection } from '../../types/schema'
import ActivityIndicator from '../ActivityIndicator'
import { FormattedMessage } from 'react-intl'

const GET_WEEK_DAYS = gql`
  fragment RecipeParts on Recipe {
    name
    serves
    recipeIngredients {
      quantity
      quantityFrom
      quantityTo
      quantityType
      ingredient {
        name
        department
      }
    }
  }

  query ListWeekDaysQuery($from: String!, $to: String!) {
    listWeekDays(filter: { date: { ge: $from, le: $to } }) {
      items {
        date
        breakfastRecipe {
          ...RecipeParts
        }
        lunchRecipe {
          ...RecipeParts
        }
        dinnerRecipe {
          ...RecipeParts
        }
        treatRecipe {
          ...RecipeParts
        }
      }
    }
  }
`

interface IngredientUnitQuantity {
  quantity: number
  quantityType: string
}

interface DepartmentIngredientsList {
  [ingredientName: string]: IngredientUnitQuantity
}

interface IngredientsList {
  [departmentName: string]: DepartmentIngredientsList
}

function sumRecipeIngredients(list: Record<string, IngredientsList>, recipe: Maybe<Recipe> | undefined) {
  recipe?.recipeIngredients.forEach((recipeIngredient) => {
    let recipeQuantity = (recipeIngredient.quantity ?? 0) + (recipeIngredient.quantityTo ?? 0)
    const divisor = recipe.serves > 2 ? recipe.serves / 2 : 1

    recipeQuantity = Math.ceil(recipeQuantity / divisor)

    if (!list[recipeIngredient.ingredient.department]) {
      list[recipeIngredient.ingredient.department] = {}
    }

    if (!list[recipeIngredient.ingredient.department][recipeIngredient.ingredient.name]) {
      list[recipeIngredient.ingredient.department][recipeIngredient.ingredient.name] = {
        [recipeIngredient.quantityType]: {
          quantity: recipeQuantity,
          quantityType: recipeIngredient.quantityType
        }
      }
    } else {
      if (!list[recipeIngredient.ingredient.department][recipeIngredient.ingredient.name][recipeIngredient.quantityType]) {
        list[recipeIngredient.ingredient.department][recipeIngredient.ingredient.name][recipeIngredient.quantityType] = {
          quantity: recipeQuantity,
          quantityType: recipeIngredient.quantityType
        }
      } else {
        list[recipeIngredient.ingredient.department][recipeIngredient.ingredient.name][recipeIngredient.quantityType].quantity += recipeQuantity
      }
    }
  })
}

export default function ShoppingList() {
  const [searchParams] = useSearchParams()
  const [ingredientsList, setIngredientsList] = useState<Record<string, IngredientsList> | undefined>()
  const [getWeekDays, { loading: isLoadingWeekDays, error: weekDaysError, data: weekDays }] = useLazyQuery<{
    listWeekDays: WeekDayConnection
  }>(GET_WEEK_DAYS)

  useEffect(() => {
    if (searchParams.get('from') == null || searchParams.get('to') === null) {
      return
    }

    getWeekDays({
      variables: {
        from: searchParams.get('from'),
        to: searchParams.get('to')
      }
    })
  }, [searchParams, getWeekDays])

  useEffect(() => {
    if (!weekDays?.listWeekDays.items) {
      return
    }

    const reducedIngredients: Record<string, IngredientsList>  = {} 

    weekDays.listWeekDays.items.forEach((weekDay) => {
      sumRecipeIngredients(reducedIngredients, weekDay?.breakfastRecipe)
      sumRecipeIngredients(reducedIngredients, weekDay?.lunchRecipe)
      sumRecipeIngredients(reducedIngredients, weekDay?.dinnerRecipe)
      sumRecipeIngredients(reducedIngredients, weekDay?.treatRecipe)
    })

    setIngredientsList(reducedIngredients)
  }, [weekDays])

  if (isLoadingWeekDays) {
    return <ActivityIndicator fullscreen />
  }

  if (weekDaysError) {
    console.error(weekDaysError)
    return <div>{JSON.stringify(weekDaysError)}</div>
  }

  if (!weekDays?.listWeekDays.items || !ingredientsList) {
    return (
      <div className="uk-container uk-text-center">
        <div className="uk-alert uk-alert-primary">
          <FormattedMessage id="plan-dates.none-selected.label" />
        </div>
      </div>
    )
  }

  return (
    <ul className='uk-list uk-list-disc'>
      {Object.entries(ingredientsList).map(([departmentName, departmentIngredientsList]) => {
        return (<>
          <li>
            <FormattedMessage id={`department.name.${departmentName}`} />
            <ul  className='uk-list uk-list-collapse uk-list-circle uk-margin-bottom'>
              {Object.entries(departmentIngredientsList).map(([ingredientName, ingredientQuantity]) => {
                return (
                  <li key={ingredientName}>
                    <Link to={`https://www.countdown.co.nz/shop/searchproducts?search=${encodeURIComponent(ingredientName)}`} target="_blank">
                      {ingredientName}
                    </Link>{' '}
                    -{' '}
                    {Object.entries(ingredientQuantity).map(([ unit, quantity ], index, arr) => {
                      return (
                        <span>
                          <FormattedMessage id={`quantity.unit.${quantity.quantityType}`} values={{ quantity: quantity.quantity }} />
                          <span>{index + 1 < arr.length && (<>,&nbsp;</>)}</span>
                        </span>
                      )
                    })}
                  </li>
                )
              })}
          </ul>
        </li>
      </>)
      })}
    </ul>
  )
}
