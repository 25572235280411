import { FormEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { selectEmailAddress } from '../../slices/auth'
import ActivityIndicator from '../ActivityIndicator'
import useAuth from '../../hooks/auth'
import { FormattedMessage, useIntl } from 'react-intl'

const SignIn = () => {
  const { isLoading, hydrateSignIn, signIn, isSignedIn } = useAuth()
  const { formatMessage } = useIntl()
  const [emailAddress, setEmailAddress] = useState('')
  const [password, setPassword] = useState('')
  const confirmedEmailAddress = useSelector(selectEmailAddress)

  useEffect(() => {
    hydrateSignIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (confirmedEmailAddress) {
      setEmailAddress(confirmedEmailAddress)
    }
  }, [confirmedEmailAddress])

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    await signIn(emailAddress, password)
  }

  if (isLoading) {
    return <ActivityIndicator fullscreen />
  }

  if (isSignedIn) {
    return <Navigate to="/" />
  }

  return (
    <div className="uk-container uk-width-xlarge uk-margin-large-top">
      <h2 className="uk-margin-large-top">
        <FormattedMessage id="sign-in.title" />
      </h2>
      <form className="uk-margin-small-top" action="#" method="POST" onSubmit={(e) => handleSubmit(e)}>
        <div className="uk-grid-small uk-child-width-1-1" uk-grid="true">
          <div>
            <input
              id="email-address"
              type="email"
              autoComplete="off"
              value={emailAddress}
              required
              disabled={false}
              className="uk-input"
              placeholder={formatMessage({ id: 'sign-in.form.email.label' })}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <div>
            <input
              id="password"
              type="password"
              autoComplete="off"
              value={password}
              required
              disabled={false}
              className="uk-input"
              placeholder={formatMessage({ id: 'sign-in.form.password.label' })}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="uk-text-right">
            <button type="submit" className="uk-button uk-button-primary" disabled={false} onClick={handleSubmit}>
              <FormattedMessage id="sign-in.form.submit.link" />
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SignIn
