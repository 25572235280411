import { useEffect, useState } from 'react'
import { Book, Recipe, WeekDay } from '../../../types/schema'
import format from 'date-fns/format'
import { gql, useMutation } from '@apollo/client'
import RecipeSelect from '../../RecipeSelect'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

export interface DayProps {
  date: Date
  weekDay: WeekDay | undefined
  books: Book[]
}

interface RecipeSelectionProps {
  mealName: string
  books: Book[]
  selectedRecipe: Recipe | undefined
  onRecipeSelected: (recipe: Recipe | undefined) => void
}

const CREATE_WEEK_DAY = gql`
  mutation MyMutation($date: AWSDate!, $breakfastRecipeId: ID, $dinnerRecipeId: ID, $lunchRecipeId: ID, $treatRecipeId: ID) {
    createWeekDay(
      input: {
        date: $date
        breakfastRecipeId: $breakfastRecipeId
        dinnerRecipeId: $dinnerRecipeId
        lunchRecipeId: $lunchRecipeId
        treatRecipeId: $treatRecipeId
      }
    ) {
      date
    }
  }
`

const UPDATE_WEEK_DAY = gql`
  mutation MyMutation($date: AWSDate!, $breakfastRecipeId: ID, $dinnerRecipeId: ID, $lunchRecipeId: ID, $treatRecipeId: ID) {
    updateWeekDay(
      input: {
        date: $date
        breakfastRecipeId: $breakfastRecipeId
        dinnerRecipeId: $dinnerRecipeId
        lunchRecipeId: $lunchRecipeId
        treatRecipeId: $treatRecipeId
      }
    ) {
      date
    }
  }
`

const RecipeSelection = ({ books, mealName, selectedRecipe, onRecipeSelected }: RecipeSelectionProps) => {
  return (
    <div className="uk-grid uk-grid-small uk-child-width-1-1">
      <div>{mealName}:</div>
      <div>
        <RecipeSelect books={books} defaultValue={selectedRecipe} onChange={onRecipeSelected} />
      </div>
    </div>
  )
}

export default function Day(props: DayProps) {
  const [breakfastRecipe, setbreakfastRecipe] = useState<Recipe | undefined>(props.weekDay?.breakfastRecipe ?? undefined)
  const [lunchRecipe, setLunchRecipe] = useState<Recipe | undefined>(props.weekDay?.lunchRecipe ?? undefined)
  const [dinnerRecipe, setDinnerRecipe] = useState<Recipe | undefined>(props.weekDay?.dinnerRecipe ?? undefined)
  const [treatRecipe, setTreatRecipe] = useState<Recipe | undefined>(props.weekDay?.treatRecipe ?? undefined)
  const [totalCalories, setTotalCalories] = useState(0)
  const [createWeekDayMutation] = useMutation(CREATE_WEEK_DAY)
  const [updateWeekDayMutation] = useMutation(UPDATE_WEEK_DAY)

  useEffect(() => {
    setTotalCalories(
      (breakfastRecipe?.calories ?? 0) + (lunchRecipe?.calories ?? 0) + (dinnerRecipe?.calories ?? 0) + (treatRecipe?.calories ?? 0)
    )

    // Create the week day, only if it does not exist and there is at least one meal set.
    if (!props.weekDay && (breakfastRecipe?.id || lunchRecipe?.id || dinnerRecipe?.id || treatRecipe?.id)) {
      createWeekDayMutation({
        variables: {
          date: format(props.date, 'yyyy-MM-dd'),
          breakfastRecipeId: breakfastRecipe?.id ?? null,
          lunchRecipeId: lunchRecipe?.id ?? null,
          dinnerRecipeId: dinnerRecipe?.id ?? null,
          treatRecipeId: treatRecipe?.id ?? null
        }
      })
    } else if (props.weekDay) {
      // If one of the meals has changed, then update the week day.
      if (
        props.weekDay.breakfastRecipe?.id !== breakfastRecipe?.id ||
        props.weekDay.lunchRecipe?.id !== lunchRecipe?.id ||
        props.weekDay.dinnerRecipe?.id !== dinnerRecipe?.id ||
        props.weekDay.treatRecipe?.id !== treatRecipe?.id
      ) {
        updateWeekDayMutation({
          variables: {
            date: format(props.date, 'yyyy-MM-dd'),
            breakfastRecipeId: breakfastRecipe?.id ?? null,
            lunchRecipeId: lunchRecipe?.id ?? null,
            dinnerRecipeId: dinnerRecipe?.id ?? null,
            treatRecipeId: treatRecipe?.id ?? null
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakfastRecipe, lunchRecipe, dinnerRecipe, treatRecipe])

  const calorieClassNames = classNames('uk-card-footer uk-text-meta', {
    'uk-text-warning': totalCalories > 800
  })

  return (
    <div className="uk-card uk-card-default uk-card-small">
      <div className="uk-card-header">
        <div className="uk-grid-small uk-flex-middle">
          <div className="uk-width-expand">
            <h3 className="uk-card-title uk-margin-remove-bottom">{format(props.date, 'EEEE')}</h3>
            <p className="uk-text-meta uk-margin-remove-top">
              <time dateTime={props.date.toISOString()}>{format(props.date, 'MMMM dd, yyyy')}</time>
            </p>
          </div>
        </div>
      </div>
      <div className="uk-card-body">
        <RecipeSelection books={props.books} mealName="Breakfast" selectedRecipe={breakfastRecipe} onRecipeSelected={setbreakfastRecipe} />
        <RecipeSelection books={props.books} mealName="Lunch" selectedRecipe={lunchRecipe} onRecipeSelected={setLunchRecipe} />
        <RecipeSelection books={props.books} mealName="Dinner" selectedRecipe={dinnerRecipe} onRecipeSelected={setDinnerRecipe} />
        <RecipeSelection books={props.books} mealName="Treat" selectedRecipe={treatRecipe} onRecipeSelected={setTreatRecipe} />
      </div>
      <div className={calorieClassNames}>
        <FormattedMessage id="calendar.day.total-calories" values={{ 
          totalCalories,
          remainingOverLabel: totalCalories > 800 ? 'over' : 'remaining',
          remainingOver: Math.abs(800 - totalCalories)
        }} />
      </div>
    </div>
  )
}
