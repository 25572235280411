import { gql, useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Recipe, WeekDay, WeekDayConnection } from '../../types/schema'
import ActivityIndicator from '../ActivityIndicator'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isSameDay from 'date-fns/isSameDay'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import { FormattedMessage } from 'react-intl'

const GET_WEEK_DAYS = gql`
  query ListWeekDaysQuery($from: String!, $to: String!) {
    listWeekDays(filter: { date: { ge: $from, le: $to } }) {
      items {
        date
        breakfastRecipe {
          id
          name
          calories
          page
          book {
            name
            image
          }
        }
        lunchRecipe {
          id
          name
          calories
          page
          book {
            name
            image
          }
        }
        dinnerRecipe {
          id
          name
          calories
          page
          book {
            name
            image
          }
        }
        treatRecipe {
          id
          name
          calories
          page
          book {
            name
            image
          }
        }
      }
    }
  }
`

interface WeekDayMealProps {
  recipe: Recipe
}

function WeekDayMeal({ recipe }: WeekDayMealProps) {
  return (
    <>
      <div className="uk-margin-small-bottom uk-text-bold">{recipe?.name || '-'}</div>
      <div className="uk-text-meta uk-grid-collapse" uk-grid="true">
        <div>
          {recipe?.book?.image && <img className="uk-margin-right" width="50" src={recipe?.book?.image} alt={recipe?.book?.name} />}
        </div>
        <div>
          <ul className="uk-list">
            <li>
              <FormattedMessage
                id="view-plan.day.meal.book.name"
                values={{
                  bookName: recipe?.book?.name
                }}
              />
            </li>
            <li>
              {recipe?.page ? (
                <FormattedMessage
                  id="view-plan.day.meal.book.page"
                  values={{
                    page: recipe?.page
                  }}
                />
              ) : (
                '-'
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default function ViewPlan() {
  const [searchParams] = useSearchParams()
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [getWeekDays, { loading: isLoadingWeekDays, error: weekDaysError, data: weekDays }] = useLazyQuery<{
    listWeekDays: WeekDayConnection
  }>(GET_WEEK_DAYS)

  useEffect(() => {
    if (searchParams.get('from') == null || searchParams.get('to') === null) {
      return
    }

    getWeekDays({
      variables: {
        from: searchParams.get('from'),
        to: searchParams.get('to')
      }
    })

    setStartDate(parseISO(searchParams.get('from') as string))
    setEndDate(parseISO(searchParams.get('to') as string))
  }, [searchParams, getWeekDays])

  const findPlannedDay = (date: Date): WeekDay | undefined => {
    const plannedDay = weekDays?.listWeekDays.items?.find((pd) => {
      if (!pd?.date) {
        return false
      }

      return isSameDay(date, parseISO(pd.date))
    })

    if (!plannedDay) {
      return
    }

    return plannedDay
  }

  if (isLoadingWeekDays) {
    return <ActivityIndicator fullscreen />
  }

  if (weekDaysError) {
    console.error(weekDaysError)
    return <div>{JSON.stringify(weekDaysError)}</div>
  }

  if (!startDate || !endDate || !weekDays?.listWeekDays.items) {
    return (
      <div className="uk-container uk-text-center">
        <div className="uk-alert uk-alert-primary">
          <FormattedMessage id="plan-dates.none-selected.label" />
        </div>
      </div>
    )
  }

  return (
    <table className="uk-table uk-table-striped uk-table-small">
      <thead>
        <tr>
          <td>&nbsp;</td>
          {eachDayOfInterval({ start: startDate, end: endDate }).map((d) => {
            return <td key={`${d}-day-heading`}>{format(d, 'EEEE MMMM dd, yyyy')}</td>
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <FormattedMessage id="view-plan.day.meal.breakfast" />
          </td>
          {eachDayOfInterval({ start: startDate, end: endDate }).map((d) => {
            const weekDay = findPlannedDay(d)
            return (
              <td key={`${d}-day-breakfast-meal`} className="uk-text-small">
                {weekDay?.breakfastRecipe ? <WeekDayMeal recipe={weekDay?.breakfastRecipe} /> : '-'}
              </td>
            )
          })}
        </tr>
        <tr>
          <td>
            <FormattedMessage id="view-plan.day.meal.lunch" />
          </td>
          {eachDayOfInterval({ start: startDate, end: endDate }).map((d) => {
            const weekDay = findPlannedDay(d)
            return (
              <td key={`${d}-day-lunch-meal`} className="uk-text-small">
                {weekDay?.lunchRecipe ? <WeekDayMeal recipe={weekDay?.lunchRecipe} /> : '-'}
              </td>
            )
          })}
        </tr>
        <tr>
          <td>
            <FormattedMessage id="view-plan.day.meal.dinner" />
          </td>
          {eachDayOfInterval({ start: startDate, end: endDate }).map((d) => {
            const weekDay = findPlannedDay(d)
            return (
              <td key={`${d}-day-dinner-meal`} className="uk-text-small">
                {weekDay?.dinnerRecipe ? <WeekDayMeal recipe={weekDay?.dinnerRecipe} /> : '-'}
              </td>
            )
          })}
        </tr>
        <tr>
          <td>
            <FormattedMessage id="view-plan.day.meal.treat" />
          </td>
          {eachDayOfInterval({ start: startDate, end: endDate }).map((d) => {
            const weekDay = findPlannedDay(d)
            return (
              <td key={`${d}-day-treat-meal`} className="uk-text-small">
                {weekDay?.treatRecipe ? <WeekDayMeal recipe={weekDay?.treatRecipe} /> : '-'}
              </td>
            )
          })}
        </tr>
      </tbody>
    </table>
  )
}
